// import { FETCH_CARDS } from "./sagas"

const actions = {
  SET_STATE: 'wallet/SET_STATE',
  WALLET: 'wallet/WALLET',
  TRANSACTIONS: 'wallet/TRANSACTIONS',
  PAYSTCK_INIT: 'wallet/PAYSTCK_INIT',
  FETCH_CARDS: 'wallet/FETCH_CARDS',
  FLUTTER_CALLBACK: 'wallet/FLUTTER_CALLBACK',
  FETCH_VIRTUAL_ACCOUNT: 'wallet/FETCH_VIRTUAL_ACCOUNT',
  EXPORT_WALLET_TRANSACTIONS: 'wallet/EXPORT_WALLET_TRANSACTIONS',
}

export default actions
