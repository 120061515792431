const actions = {
  SET_STATE: 'utilities/SET_STATE',
  UTILITIES: 'utilities/UTILITIES',
  REGENERATE_TOKEN:'utilities//REGENERATE_TOKEN',
  VENDING_DEBIT: 'utilities/VENDING_DEBIT',
  VENDING_REPAY: 'utilities/VENDING_REPAY',
  UTILITY_PAYMENT: 'utilities/UTILITY_PAYMENT',
  UTILITY_BUILDING_DETAILS: 'utilities/UTILITY_BUILDING_DETAILS',
  UTILITY_ELECTRICITY_PRICE_PER_UNIT: 'utilities/UTILITY_ELECTRICITY_PRICE_PER_UNIT',
  UTILITY_CONVINIENCE_FEE: 'utilities/UTILITY_CONVINIENCE_FEE',
  FETCH_VIRTUAL_ACCOUNT: 'billing/FETCH_VIRTUAL_ACCOUNT',
  UNIT_PURCHASE: 'utilities/UNIT_PURCHASE',
  EXPORT_UTILITIES: 'utilities/EXPORT_UTILITIES',
}

export default actions
