import actions from './actions'

const initialState = {
  wallet: 0,
  wallet_data: [],
  exportWallTransactions: [],
  loading: false,
  modal: false,
  transaction: [],
  paymentSuccess: false,
  addFundsLoading: false,
  paymentInfo: {},
  paymentCards: {},
  virtualAccount: [],
}

export default function walletReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
