import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwts from 'services/jwt'
import actions from './actions'
// import { store as reduxStore } from 'index'

const jwt = {
  visitors: jwts.fetchVisitors,
  permanentVisitors: jwts.permanentVisitors,
  addVisitors: jwts.addVisitors,
  editVisitors: jwts.editVisitors,
  deleteVisitors: jwts.deleteVisitors,
  dailyVisitors: jwts.dailyVisitors,
  exportFetchVisitors: jwts.exportFetchVisitors,
}

export function* VISITORS({ payload }) {
  yield put({
    type: 'visitors/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.visitors, payload)
  if (success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        visitors: success.data.all_visitors_lists,
        visitData: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DAILY_VISITORS({ payload }) {
  console.log(payload)
  yield put({
    type: 'visitors/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // console.log(payload)
  const success = yield call(jwt.dailyVisitors, payload)
  if (success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        dailyVisitors: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

// PERMANENT VISITORS
export function* PERMANENT_VISITORS() {
  yield put({
    type: 'visitors/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.permanentVisitors)
  if (success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        permanentVisitors: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* ADD_VISITORS({ payload }) {
  console.log(payload)
  yield put({
    type: 'visitors/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.addVisitors, payload)
  if (success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        // visitors: success.data.data,
        loading: false,
      },
    })
    notification.success({
      message: success.message,
      description: success.message,
    })    
    // setTimeout(() => {
    //   window.location.reload();;
    // }, 1000);
    yield put({
      type: 'visitors/VISITORS',
    })
    yield put({
      type: 'visitors/DAILY_VISITORS',
    })
  }
  // console.log(success.data)
  if (!success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* EDIT_VISITORS(payload) {
  yield put({
    type: 'visitors/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // console.log('this is payload')
  // console.log(payload.data)
  const success = yield call(jwt.editVisitors, payload.data)
  if (success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        // visitors: success.data.data,
        loading: false,
      },
    })
    notification.success({
      message: success.message,
      description: success.message,
    })    
    yield put({
      type: 'visitors/VISITORS',
    })
    yield put({
      type: 'visitors/DAILY_VISITORS',
    })
  }
  // console.log(success.data)
  if (!success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE_VISITORS(payload) {
  yield put({
    type: 'visitors/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // console.log('this is payload')
  // console.log(payload.data)
  const success = yield call(jwt.deleteVisitors, payload.data)
  if (success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        // visitors: success.data.data,
        loading: false,
      },
    })
    yield put({
      type: 'visitors/VISITORS',
    })
  }
  // console.log(success.data)
  if (!success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* EXPORT_VISITORS(payload) {
  yield put({
    type: 'visitors/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.exportFetchVisitors, payload.data)
  if (success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        exportVisitors: success.data,
        loading: false,
      },
    })
    yield put({
      type: 'visitors/VISITORS',
    })
  }
  if (!success.status) {
    yield put({
      type: 'visitors/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export default function* rootSaga() {
  yield all([takeEvery(actions.VISITORS, VISITORS)])
  yield all([takeEvery(actions.DAILY_VISITORS, DAILY_VISITORS)])
  yield all([takeEvery(actions.PERMANENT_VISITORS, PERMANENT_VISITORS)])
  yield all([takeEvery(actions.ADD_VISITORS, ADD_VISITORS)])
  yield all([takeEvery(actions.EDIT_VISITORS, EDIT_VISITORS)])
  yield all([takeEvery(actions.DELETE_VISITORS, DELETE_VISITORS)])
  yield all([takeEvery(actions.EXPORT_VISITORS, EXPORT_VISITORS)])
}
