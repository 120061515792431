import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import dashboard from './dashboard/reducers'
import profile from './profile/reducers'
import billing from './billing/reducers'
import wallet from './wallet/reducers'
import transaction from './transaction/reducers'
import utilities from './utilities/reducers'
import visitors from './visitors/reducers'
import complaints from './complaints/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    dashboard,
    profile,
    wallet,
    transaction,
    billing,
    utilities,
    visitors,
    complaints,
  })
