import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
// import { history } from 'index'
import * as jwts from 'services/jwt'
import actions from './actions'

const jwt = {
  wallet: jwts.wallet,
  transaction: jwts.transaction,
  paystackInit: jwts.paystackInit,
  fetchCards: jwts.fetchCards,
  fundWallet: jwts.fundWallet,
  fetchVirtualAccount: jwts.fetchVirtualAccount,
  exportWalletTransactions: jwts.exportWalletTransactions,
}

export function* WALLET(data) {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.wallet, data)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        wallet: success.data.wallet_balance,
        wallet_data: success.data.wallet_history,
        loading: false,
      },
    })
    // console.log(success.data)
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* TRANSACTIONS(data) {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.transaction, data)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        transaction: success.data,
        loading: false,
      },
    })
    // console.log(success.data)
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* PAYSTACK_INIT({ payload }) {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.paystackInit, payload)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
        paymentSuccess: true,
        paymentInfo: success.data,
      },
    })
  }

  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* FETCH_CARDS() {
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      addFundsLoading: true,
    },
  })
  const success = yield call(jwt.fetchCards)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        paymentCards: success.data,
        addFundsLoading: false,
        modal: true,
      },
    })
  }

  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        addFundsLoading: false,
      },
    })
  }
}

export function* FLUTTER_CALLBACK({payload}) {
  // console.log(payload)
  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      addFundsLoading: true,
    },
  })
  const success = yield call(jwt.fundWallet, payload)
  if (success.status) {
    yield put({
      type: 'wallet/SET_STATE',
    })
    notification.success({
      message: success.message,
      description: success.message,
    })
    yield put({
      type: 'wallet/WALLET',
    })
  }

  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        addFundsLoading: false,
      },
    })
    notification.success({
      message: success.message,
      description: success.message,
    })
  }
}



export function* FETCH_VIRTUAL_ACCOUNT() {
  // eslint-disable-next-line camelcase

  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
      paystackInitLoading:true,
    },
  })
  const success = yield call(jwt.fetchVirtualAccount)
  if (success) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
        virtualAccount: success.data
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* EXPORT_WALLET_TRANSACTIONS() {
  // eslint-disable-next-line camelcase

  yield put({
    type: 'wallet/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.exportWalletTransactions)
  if (success) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
        exportWallTransactions: success.data
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'wallet/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.WALLET, WALLET),
    takeEvery(actions.TRANSACTIONS, TRANSACTIONS),
    takeEvery(actions.PAYSTCK_INIT, PAYSTACK_INIT),
    takeEvery(actions.FETCH_CARDS, FETCH_CARDS),
    takeEvery(actions.FLUTTER_CALLBACK, FLUTTER_CALLBACK),
    takeEvery(actions.FETCH_VIRTUAL_ACCOUNT, FETCH_VIRTUAL_ACCOUNT),
    takeEvery(actions.EXPORT_WALLET_TRANSACTIONS, EXPORT_WALLET_TRANSACTIONS),
  ])
}
