import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
import { history } from 'index'
import * as jwts from 'services/jwt'
import actions from './actions'

const jwt = {
  utilities: jwts.utilityHistory,
  utilityPayment: jwts.utilityPayment,
  vendingRepayment: jwts.vendingRepayment,
  utilitiesBuilding: jwts.utilityBuilding,
  vendingDebtDetails: jwts.vendingDebtDetails,
  regenerateToken: jwts.regenerateToken,
  utilitiesElectricity: jwts.utilityElectricity,
  utilityConvinienceFee: jwts.utilityConvinienceFee,
  fetchVirtualAccount: jwts.fetchVirtualAccount,
  flutterwaveUnit: jwts.flutterwaveUnit,
  exportUtilityHistory: jwts.exportUtilityHistory,
}

export function* UTILITIES( payload ) {
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      histLoading: true,
    },
  })
  const success = yield call(jwt.utilities, payload)
  if (success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        utilities: success.data,
        histLoading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        histLoading: false,
      },
    })
  }
}


export function* UNIT_PURCHASE({ payload }) {
  // eslint-disable-next-line camelcase
  // const { amount, payment_method } = payload
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      // modal: true,
      loading: true,
    },
  })
  const success = yield call(jwt.flutterwaveUnit, payload)
  if (success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        // modal: false,
        loading: false,
      },
    })
    notification.success({
      message: success.message,
      description: success.message,
    })
    yield put({ type: 'utilities/UTILITIES' })
    yield put({ type: 'utilities/VENDING_DEBIT' })
    // yield history.push('/vending')  
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })    
    notification.error({
      message: 'Warning!',
      description: success.message,
    })
    yield put({
      type: 'utilities/UTILITIES',
    })
  }
}

export function* UTILITY_PAYMENT({ payload }) {
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.utilityPayment, payload)
  if (success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
      description: success.message,
    })
    yield put({
      type: 'utilities/UTILITIES',
    })
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* VENDING_REPAY({ payload }) {
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.vendingRepayment, payload)
  if (success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.success({
      message: success.message,
      description: success.message,
    })    
    setTimeout(() => {
      window.location.reload();;
    }, 1000);
    yield put({
      type: 'utilities/VENDING_DEBIT',
    })
    yield history.push('/vending')
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

// saga function to call the utility page residence details
export function* UTILITY_BUILDING_DETAILS() {
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.utilitiesBuilding)
  if (success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        // utilities: success.data.data,
        buildingDetails: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* VENDING_DEBIT() {
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      histLoading: true,
    },
  })
  const success = yield call(jwt.vendingDebtDetails)
  if (success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        vendingDebt: success.data,
        histLoading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        histLoading: false,
      },
    })
  }
}


export function* REGENERATE_TOKEN() {
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      // modal: true,
      loading: true,
    },
  })
  const success = yield call(jwt.regenerateToken)
  if (success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        // modal: false,
        loading: false,
      },
    })
    notification.success({
      message: success.message,
      description: success.message,
    })
    yield put({
      type: 'utilities/UTILITIES',
    })
    yield history.push('/vending')
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })    
    notification.error({
      message: success.message,
      description: success.message,
    })
    yield put({
      type: 'utilities/UTILITIES',
    })
  }
}

// saga function to call the utility page electricity unit calculator
export function* UTILITY_ELECTRICITY_PRICE_PER_UNIT() {
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.utilitiesElectricity)
  if (success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        // utilities: success.data.data,
        electricityUnit: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

// saga function to call the convinience fee api
export function* UTILITY_CONVINIENCE_FEE() {
  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.utilityConvinienceFee)
  if (success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        // utilities: success.data.data,
        convinienceFee: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* FETCH_VIRTUAL_ACCOUNT() {
  // eslint-disable-next-line camelcase

  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      loading: true,
      paystackInitLoading:true,
    },
  })
  const success = yield call(jwt.fetchVirtualAccount)
  if (success) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
        virtualAccount: success.data
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* EXPORT_UTILITIES() {
  // eslint-disable-next-line camelcase

  yield put({
    type: 'utilities/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.exportUtilityHistory)
  if (success) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
        exportUtilities: success.data
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'utilities/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export default function* rootSaga() {
  yield all([takeEvery(actions.UTILITIES, UTILITIES)])
  yield all([takeEvery(actions.UTILITY_PAYMENT, UTILITY_PAYMENT)])
  yield all([takeEvery(actions.VENDING_REPAY, VENDING_REPAY)])
  yield all([takeEvery(actions.UTILITY_BUILDING_DETAILS, UTILITY_BUILDING_DETAILS)])
  yield all([takeEvery(actions.VENDING_DEBIT, VENDING_DEBIT)])
  yield all([takeEvery(actions.REGENERATE_TOKEN, REGENERATE_TOKEN)])
  yield all([takeEvery(actions.UTILITY_ELECTRICITY_PRICE_PER_UNIT, UTILITY_ELECTRICITY_PRICE_PER_UNIT),])
  yield all([takeEvery(actions.UTILITY_CONVINIENCE_FEE, UTILITY_CONVINIENCE_FEE),])
  yield all([takeEvery(actions.FETCH_VIRTUAL_ACCOUNT, FETCH_VIRTUAL_ACCOUNT),])
  yield all([takeEvery(actions.UNIT_PURCHASE, UNIT_PURCHASE),])
  yield all([takeEvery(actions.EXPORT_UTILITIES, EXPORT_UTILITIES),])
}
