import actions from './actions'

const initialState = {
  dashboard: [],
  latestTransactions: [],
  billingTransactions: [],
  overdueInvoices: [],
  walletBalance: 0,
  overdueBalance: 0,
  outstandingBalance: 0,
  loading: false,
  pagination:[],
}

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
