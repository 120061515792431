/* eslint-disable no-restricted-globals */
import React, { Fragment, useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
// import NProgress from 'nprogress'
import store from 'store'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {

  const userData = store.get('estate_user')

  useEffect(() => {
    window.scrollTo(0, 0)
    window.scroll(0, 0)
  }, [pathname])

  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    // NProgress.start()
  }
  setTimeout(() => {
    // NProgress.done()
    window.scrollTo({ top: 0, behavior: 'smooth' })
    previousPath = currentPath
  }, 300)

  // const query = new URLSearchParams(window.location.search) || false
  // const id = query && query.get('id')

  // Layout Rendering'
  const getLayout = () => {
    if (/^\/page(?=\/|$)/i.test(pathname) || pathname === `/home` || pathname === `/`) {
      return 'public'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = userData && userData.token
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'auth'
  const isMainLayout = getLayout() === 'main'

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // redirect to login page if current is not login page and user not authorized
    if (isMainLayout && !isUserAuthorized) {
      return <Redirect to="/auth/login" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      {/* <Helmet titleTemplate="Estate Plus | %s" title="Database Admin Control" /> */}
      <Helmet titleTemplate="Estate Plus" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
