import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
// import { history } from 'index'
import * as jwts from 'services/jwt'
import actions from './actions'

const jwt = {
  dashboard: jwts.dashboard,
}

export function* DASHBOARD( payload ) {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.dashboard, payload)
  if (success.status) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        latestTransactions: success.data.latestTransactions,
        billingTransactions: success.data.billing_transactions,
        overdueInvoices: success.data.overdueInvoices,
        walletBalance: success.data.walletBalance,
        overdueBalance: success.data.overdueBalance,
        outstandingBalance: success.data.outstandingBalance,
        pagination: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.DASHBOARD, DASHBOARD)])
}
