import { message } from 'antd'
import axios from 'axios'
// import { useQuery } from 'react-query'
// import { routerMiddleware } from 'connected-react-router'
// import createSagaMiddleware from 'redux-saga'
import { history, store as storeData } from 'index'
import store from 'store'
// import estateData from 'services/utils'

// page url
const baseUrl = 'https://default.api.estateplusng.com/api'
const backUpApi = 'https://173.212.202.67/estate_plus/index.php/api'
export const pageURL = 'http://localhost:3000/'
// const query = new URLSearchParams(window.location.search)
// const id = query && query.get('id') || 0

// function myAsyncFunction(url) {
//   return new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest();
//     xhr.open("POST", url, {estate_id: id});
//     xhr.onload = () => resolve(xhr.responseText);
//     xhr.onerror = () => reject(xhr.statusText);
//     xhr.send();
//   });
// }

// console.log(myAsyncFunction(`${baseUrl}/fetch_company_details`))

const estateData =
  document.readyState === 'complete'
    ? storeData.getState()?.user?.estateID
    : store.get('estatePlus_saas') || false
// console.log(estateData)

export const flutterwaveKey = estateData && estateData.flutterwave_key
export const paystackKey = estateData && estateData.paystack_key
const baseApi = estateData ? estateData.base_api : backUpApi

const apiClient = axios.create({
  baseURL: !estateData ? baseUrl : baseApi,
  // baseURL: 'http://173.212.202.67/estate_plus/index.php/api',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const userData = store.get('estate_user')
  if (userData) {
    request.headers.Authorization = `Bearer ${userData.token}`
    request.headers.AccessToken = userData
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data && data.status_code === 401 && data.message === 'Token has expired') {
    const estateId = store.get('estate_id') || false
    store.remove('estate_user')
    store.remove('estatePlus_saas')
    history.push(`/auth/login?id=${estateId}`)
    message.error({
      content: 'Token has expired, please re-login to continue.',
      duration: 4,
      key: 'updatable',
    })
  }
})

export const numberFormat = value => {
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(value)
}

export default apiClient
