import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { notification } from 'antd'
// import { history } from 'index'
import * as jwts from 'services/jwt'
import actions from './actions'

const jwt = {
  transaction: jwts.transaction,
  exportTransactions: jwts.exportTransactions,
}

export function* TRANSACTIONS(data) {
  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.transaction, data)
  if (success) {
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        transaction: success.data.data,
        transPage: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* EXPORT_TRANSACTIONS() {
  yield put({
    type: 'transaction/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.exportTransactions)
  if (success) {
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        exportTransactions: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'transaction/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.TRANSACTIONS, TRANSACTIONS)])
  yield all([takeEvery(actions.EXPORT_TRANSACTIONS, EXPORT_TRANSACTIONS)])
}
