import { notification } from 'antd'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as jwts from 'services/jwt'
import actions from './actions'

const jwt = {
  displayComplaints: jwts.displayComplaints,
  complaintDetails: jwts.complaintDetails,
  addComplaint: jwts.addComplaint,
  markComplaint: jwts.markComplaint,
  fetchComplantTypes: jwts.fetchComplantTypes,
  chatReplies: jwts.chatReplies,
  replyComplaints: jwts.replyComplaints
}

export function* DISPLAY_COMPLAINTS(data) {
  yield put({
    type: 'complaints/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.displayComplaints, data)
  if (success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        complaints: success.data.data,
        comPage: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* ADD_COMPLAINT(data) {
  yield put({
    type: 'complaints/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.addComplaint, data.payload)
  if (success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        // loading: false,
      },
    })
    yield put({
      type: 'complaints/DISPLAY_COMPLAINTS',
    })
    notification.success({
      message: success.message,
      description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
      description: success.message,
    })
  }
}

export function* MARK_COMPLAINT(data) {
  yield put({
    type: 'complaints/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.markComplaint, data.data)
  if (success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        // loading: false,
      },
    })
    notification.success({
      message: success.message,
      description: success.message,
    })
    yield put({
      type: 'complaints/DISPLAY_COMPLAINTS',
    })
  }
  if (!success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: success.message,
      description: success.message,
    })
  }
}

export function* COMPLAINT_DETAIL(id) {
  yield put({
    type: 'complaints/SET_STATE',
    payload: {
      loading: true,
      complaintDetails: [],
    },
  })
  const success = yield call(jwt.complaintDetails, id)
  if (success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        complaintDetails: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REPLY_CHAT(payload) {
  // eslint-disable-next-line camelcase
  // console.log(payload)
  yield put({
    type: 'complaints/SET_STATE',
    payload: {
      // modal: true,
      loading: true,
    },
  })
  const success = yield call(jwt.replyComplaints, payload.payload)
  if (success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        // modal: false,
        loading: false,
      },
    })
    notification.success({
      message: success.message,
      description: success.message,
    })
    // console.log(success)
    yield put({
      type: 'complaints/CHAT_REPLIES',
    })
  }
  if (!success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
      description: success.message,
    })
  }
}

export function* CHAT_REPLIES(id) {
  yield put({
    type: 'complaints/SET_STATE',
    payload: {
      loading: true,
      chatReply:[]
    },
  })
  const success = yield call(jwt.chatReplies, id)
  if (success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        chatReply: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* FETCH_COMPLIANT_TYPES() {
  yield put({
    type: 'complaints/SET_STATE',
    payload: {
      loading: true,
      complaintDetails: [],
    },
  })
  const success = yield call(jwt.fetchComplantTypes)
  if (success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        complaintTypes: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'complaints/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.DISPLAY_COMPLAINTS, DISPLAY_COMPLAINTS)])
  yield all([takeEvery(actions.ADD_COMPLAINT, ADD_COMPLAINT)])
  yield all([takeEvery(actions.MARK_COMPLAINT, MARK_COMPLAINT)])
  yield all([takeEvery(actions.COMPLAINT_DETAIL, COMPLAINT_DETAIL)])
  yield all([takeEvery(actions.REPLY_CHAT, REPLY_CHAT)])
  yield all([takeEvery(actions.CHAT_REPLIES, CHAT_REPLIES)])
  yield all([takeEvery(actions.FETCH_COMPLIANT_TYPES, FETCH_COMPLIANT_TYPES)])
}
