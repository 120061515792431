import actions from './actions'

const initialState = {
  billing: [],
  billingAll: [],
  exportBillings: [],
  exportBillTransactions: [],
  paymentMethods: [],
  modal: false,
  loading: false,
  paystackInitLoading:false,
  singleBillDetails: [],
  billingDetail:[],
  singleBillTransactions: [],
  paymentInfo: {},
  paymentSuccess: false,
  comissionFee: null,
  managemantFee: null,
  billTransaction: [],
  virtualAccount: [],
  billingDet:[],
  billingInvoice: "",
  billingStatus: "",
  billingTrans: [],
  billingComm: null,
  billingMag: null,
}

export default function billingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
