import actions from './actions'

const initialState = {
  visitors: [],
  test: [],
  dailyVisitors:[],
  exportVisitors:[],
  visitData:[],
  loading: false,
  permanentVisitors: [],
}

export default function visitorsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
