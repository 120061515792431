import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message, notification } from 'antd'
// import { history } from 'index'
import * as jwts from 'services/jwt'
import actions from './actions'

const jwt = {
  billing: jwts.billing,
  paybill: jwts.paybill,
  paymentMethods: jwts.fetchPaymentMethods,
  singleBillDetails: jwts.singleBillDetails,
  flutterwaveUnit: jwts.flutterwaveUnit,
  paystackInit: jwts.paystackInit,
  billingDetails: jwts.billingDetails,
  billTransaction: jwts.billTransaction,
  fetchVirtualAccount: jwts.fetchVirtualAccount,
  exportBillings: jwts.exportBillings,
  exportBillTransactions: jwts.exportBillTransactions,
}

export function* BILLING( payload ) {
  // eslint-disable-next-line camelcase
  yield put({
    type: 'billing/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.billing, payload)
  if (success) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        billing: success.data.data,
        billingAll: success.data,
        loading: false,
        paystackInitLoading:true,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* PAYMENT_METHODS() {
  // eslint-disable-next-line camelcase
  yield put({
    type: 'billing/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.paymentMethods)
  if (success) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        paymentMethods: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* PAYBILL({ payload }) {
  // eslint-disable-next-line camelcase
  // const { amount, payment_method } = payload
  yield put({
    type: 'billing/SET_STATE',
    payload: {
      modal: true,
      loading: true,
    },
  })
  const success = yield call(jwt.paybill, payload)
  if (success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        modal: false,
        loading: false,
      },
    })
    notification.success({
      message: success.message,
      description: `Transaction Successful ${success.message}`,
    })    
    setTimeout(() => {
      window.location.reload();;
    }, 1000);
    yield put({
      type: 'billing/BILLING',
    })
    // if(notification.success){
    //   window.location.reload();
    // }
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: success.message,
      description: `Transaction Error ${success.message}`,
    })
    // message.error({
    //   content: `Transaction Error! ${success.message}`,
    //   duration: 5,
    //   style: {
    //     marginTop: '10vh',
    //   },
    // })
  }
}

export function* UNIT_PURCHASE({ payload }) {
  // eslint-disable-next-line camelcase
  // const { amount, payment_method } = payload
  yield put({
    type: 'billing/SET_STATE',
    payload: {
      modal: true,
      loading: true,
    },
  })
  const success = yield call(jwt.flutterwaveUnit, payload)
  if (success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        modal: false,
        loading: false,
      },
    })
    message.success({
      content: `Transaction Successful`,
      duration: 5,
      style: {
        marginTop: '10vh',
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
    message.error({
      content: `Transaction Error! ${success.message}`,
      duration: 5,
      style: {
        marginTop: '10vh',
      },
    })
  }
}

export function* SINGLEBILLDETAILS({ id }) {
  // eslint-disable-next-line camelcase
  yield put({
    type: 'billing/SET_STATE',
    payload: {
      loading: true,
      singleBillDetails: [],
      singleBillTransactions: [],
    },
  })
  const success = yield call(jwt.singleBillDetails, id)
  if (success) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        singleBillDetails: success.data.billing_details,
        singleBillTransactions: success.data.billing_transactions.data,
        comissionFee: success.data.commission_fee,
        managemantFee: success.data.management_fee,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* BILL_TRANSACTIONS(data) {
  // eslint-disable-next-line camelcase
  yield put({
    type: 'billing/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.billTransaction, data)
  if (success) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        billTransaction: success.data,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* BILLING_DETAIL(id) {
  yield put({
    type: 'billing/SET_STATE',
    payload: {
      loading: true,
      billingDetail: [],
      billingInvoice: [],
      billingStatus: [],
      billingTrans: [],
      billingComm: [],
      billingMag: [],
      billingDet:[]
    },
  })
  const success = yield call(jwt.billingDetails, id)
  if (success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        billingDet: success.data,
        billingDetail: success.data.billing_details,
        billingInvoice: success.data.invoice_no,
        billingStatus: success.data.bill_status,
        billingTrans: success.data.billing_transactions,
        billingComm: success.data.commission_fee,
        billingMag: success.data.management_fee,
        loading: false,
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* PAYSTACK_INIT(id) {
  // eslint-disable-next-line camelcase

  yield put({
    type: 'billing/SET_STATE',
    payload: {
      loading: true,
      paystackInitLoading:true,
    },
  })
  const success = yield call(jwt.paystackInit, id.data)
  if (success) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        paymentInfo: success.data,
        paymentSuccess: true,
        loading: false,
        paystackInitLoading: false
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* FETCH_VIRTUAL_ACCOUNT() {
  // eslint-disable-next-line camelcase

  yield put({
    type: 'billing/SET_STATE',
    payload: {
      loading: true,
      paystackInitLoading:true,
    },
  })
  const success = yield call(jwt.fetchVirtualAccount)
  if (success) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
        virtualAccount: success.data
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* EXPORT_BILLING() {
  // eslint-disable-next-line camelcase

  yield put({
    type: 'billing/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.exportBillings)
  if (success) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
        exportBillings: success?.data || [],
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* EXPORT_BILL_TRANSACTIONS() {
  // eslint-disable-next-line camelcase

  yield put({
    type: 'billing/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(jwt.exportBillTransactions)
  if (success) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
        exportBillTransactions: success?.data || [],
      },
    })
  }
  if (!success.status) {
    yield put({
      type: 'billing/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.BILLING, BILLING),
    takeEvery(actions.PAY_BILL, PAYBILL),
    takeEvery(actions.BILLING_DETAIL, BILLING_DETAIL),
    takeEvery(actions.PAYMENT_METHODS, PAYMENT_METHODS),
    takeEvery(actions.UNIT_PURCHASE, UNIT_PURCHASE),
    takeEvery(actions.SINGLE_BILL_DETAILS, SINGLEBILLDETAILS),
    takeEvery(actions.PAYSTACK_INIT, PAYSTACK_INIT),
    takeEvery(actions.BILL_TRANSACTIONS, BILL_TRANSACTIONS),
    takeEvery(actions.FETCH_VIRTUAL_ACCOUNT, FETCH_VIRTUAL_ACCOUNT),
    takeEvery(actions.EXPORT_BILLING, EXPORT_BILLING),
    takeEvery(actions.EXPORT_BILL_TRANSACTIONS, EXPORT_BILL_TRANSACTIONS),
  ])
}
