// eslint-disable-next-line no-unused-vars
// import Footer from 'components/pages/footer'
// import Header from 'components/pages/header'
import Header from 'components/pages/pages/header'
import Footer from 'components/pages/pages/footer'
import React from 'react'
import { withRouter } from 'react-router-dom'

const PublicLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="pt-md-">{children}</div>
      <Footer />
    </>
  )
}

export default withRouter(PublicLayout)
