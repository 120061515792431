const actions = {
  SET_STATE: 'complaints/SET_STATE',
  DISPLAY_COMPLAINTS: 'complaints/DISPLAY_COMPLAINTS',
  ADD_COMPLAINT: 'complaints/ADD_COMPLAINT',
  MARK_COMPLAINT: 'complaints/MARK_COMPLAINT',
  COMPLAINT_DETAIL: 'complaints/COMPLAINT_DETAIL',
  REPLY_CHAT: 'complaints/REPLY_CHAT',
  CHAT_REPLIES: 'complaints/CHAT_REPLIES',
  FETCH_COMPLIANT_TYPES: 'complaints/FETCH_COMPLIANT_TYPES',
}

export default actions
