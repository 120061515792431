import { all, takeEvery, put, call } from 'redux-saga/effects'
import { message, notification } from 'antd'
import {
  history,
  // store as storeData,
} from 'index'
import store from 'store'
// import * as firebase from 'services/firebase'
import * as jwts from 'services/jwt'
import actions from './actions'

const jwt = {
  login: jwts.login,
  register: jwts.register,
  logout: jwts.logout,
  forgotPassword: jwts.forgotPassword,
  resetPassword: jwts.resetPassword,
  estatePlusSaas: jwts.estatePlusSaas,
}

// const estateData = storeData.getState()
// console.log(estateData)

export function* ESTATE_PLUS_SAAS({ payload, baseURL }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      fetchLoading: true,
    },
  })
  // const path = window.location.pathname
  // const baseURL = 'https://default.api.estateplusng.com/api'
  const success = yield call(jwt.estatePlusSaas, payload, baseURL)
  if (success?.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        estateID: success.data,
        fetchLoading: false,
      },
    })
    store.set('estatePlus_saas', success.data)
    yield history.push('/auth/login')
    if (store.get('estate_id') !== payload.estate_id) {
      message.info({
        content: 'changes detected, system will reload to synchronize change.',
        duration: 4,
        key: 'updatable',
      })
      store.set('estate_id', payload && payload.estate_id)
      setTimeout(() => {
        sessionStorage.setItem('reloading', true)
        document.location.reload()
      }, 1000)
    }
  }
  if (!success?.status) {
    notification.warning({
      message: 'Invalid Estate Id',
      description: `${success.message} , please retry or contact your estate management`,
    })
    // yield history.push('/home')
    yield history.push('/page/sign-in')
    yield put({
      type: 'user/SET_STATE',
      payload: {
        fetchLoading: false,
      },
    })
  }
}

export function* LOGIN({ payload, baseURL }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(jwt.login, email, password, baseURL)
  if (success.status) {
    yield history.push('/dashboard')
    notification.success({
      message: 'Logged In',
      description: success.message,
    })
  }
  if (!success.status) {
    // const config = success.message.includes("does not exist")
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Logged In error',
      description: success.message,
      // description: `${success.message}, ${config && "system needs reconfiguration please wait..."}`,
    })
    // if(config){
    //   window.location.reload()
    // }
  }
}

export function* FORGOT_PASSWORD({ payload, baseURL }) {
  const { email } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(jwt.resetPassword, payload, baseURL)
  if (success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        email,
      },
    })
    yield history.push('/auth/reset-password')
    notification.success({
      message: 'Request Sent',
      description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'Failed to sent request',
      description: success.message,
    })
  }
}

export function* RESET_PASSWORD({ payload, baseURL }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(jwt.forgotPassword, payload, baseURL)
  if (success.status) {
    yield history.push('/auth/login')
    notification.success({
      message: 'Password reset',
      description: success.message,
    })
  }
  if (!success.status) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.warning({
      message: 'password reset failed',
      description: success.message,
    })
  }
}

export function* REGISTER({ payload, baseURL }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  // const { authProvider } = yield select(state => state.settings)
  const success = yield call(jwt.register, email, password, name, baseURL)
  if (success) {
    // yield put({
    //   type: 'user/LOAD_CURRENT_ACCOUNT',
    // })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  yield call(jwt.logout)
  // yield history.push(`/`)
  const estateId = store.get('estate_id')
  yield history.push(`/auth/login?id=${estateId}`)
  yield store.remove('estate_user')
  yield store.remove('accessToken')
  yield store.remove('accessToken')
  yield store.remove('estatePlus_saas')
  // yield store.clearAll()
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false, // false is default value
      loading: false,
      fetchLoading: false,
      estateID: {},
    },
  })
  notification.success({
    message: 'Logged out successfully',
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ESTATE_PLUS_SAAS, ESTATE_PLUS_SAAS),
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
