const actions = {
  SET_STATE: 'visitors/SET_STATE',
  VISITORS: 'visitors/VISITORS',
  DAILY_VISITORS: 'visitors/DAILY_VISITORS',
  ADD_VISITORS: 'visitors/ADD_VISITORS',
  EDIT_VISITORS: 'visitors/EDIT_VISITORS',
  DELETE_VISITORS: 'visitors/DELETE_VISITORS',
  SHARE_VISITORS: 'visitors/SHARE_VISITORS',
  PERMANENT_VISITORS: 'visitors/PERMANENT_VISITORS',
  EXPORT_VISITORS: 'visitors/EXPORT_VISITORS',
}

export default actions
